function log(...message) {
  console.log(
    '%cLive Preview',
    'color: white; background-color: #ff269e; padding: 2px 4px; border-radius: 2px;',
    ...message
  )
}

export default defineNuxtPlugin((app) => {
  const config = useRuntimeConfig()
  const graphqlEndpoint = config.public.GQL_HOST
  const { query } = app.$router.currentRoute.value

  if (!graphqlEndpoint) {
    return log(
      'Warning: GraphQL endpoint is not configured! Make sure GQL_HOST is published in your runtime config.'
    )
  }

  // Refresh Nuxt data
  if (process.client && query.token) {
    log('Enabling preview mode...')

    // Add query parameters to the preconfigured host
    useGqlHost(`${graphqlEndpoint}?token=${query.token}`)

    // Add live preview update event handler
    window.onmessage = async ({ data }) => {
      if (!data) return

      log('Refreshing page...')

      if (data === 'live-preview-update') {
        await refreshNuxtData()
      }
    }
  }
})
