import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["BlogAndCategories","Entry","Events","EventsAndCategories","Jobs","Navigations","TeamAndCategories","Alternates","Asset","EventPreview","BlogPostPreview","JobPreview","BlogOverviewPreview","ContentPagePreview","BlueprintBlogOverview","BlueprintBlogPost","BlueprintContentPage","BlueprintEvent","BlueprintEventOverview","BlueprintInfoScreen","BlueprintJob","BlueprintPage","BlueprintTeamMember","BlueprintTeamOverview","ReplicatorComponents","ReplicatorHeros"]}
export const GqlBlogAndCategories = (...params) => useGql()('BlogAndCategories', ...params)
export const GqlEntry = (...params) => useGql()('Entry', ...params)
export const GqlEvents = (...params) => useGql()('Events', ...params)
export const GqlEventsAndCategories = (...params) => useGql()('EventsAndCategories', ...params)
export const GqlJobs = (...params) => useGql()('Jobs', ...params)
export const GqlNavigations = (...params) => useGql()('Navigations', ...params)
export const GqlTeamAndCategories = (...params) => useGql()('TeamAndCategories', ...params)