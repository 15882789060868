import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/vercel/path0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_graphql_client_dist_runtime_plugin_mjs_jBToDIZpFa from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugins_gsap_ts_qE0fizvEy0 from "/vercel/path0/plugins/gsap.ts";
import plugins_livePreview_ts_9NO16x2OtG from "/vercel/path0/plugins/livePreview.ts";
import plugins_removePTags_ts_NNOtr2gdeF from "/vercel/path0/plugins/removePTags.ts";
import plugins_sentry_client_js_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import plugins_veeValidate_ts_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
import plugins_vue_cloudinary_image_statamic_js_CYF7m5qqCy from "/vercel/path0/plugins/vue-cloudinary-image-statamic.js";
import plugins_vue_cloudinary_image_js_AN6cd3OYHt from "/vercel/path0/plugins/vue-cloudinary-image.js";
import plugins_vue_gtag_client_js_fbnXi0Aqdd from "/vercel/path0/plugins/vue-gtag.client.js";
import plugins_vue3_lottie_client_ts_xXNcInN2H5 from "/vercel/path0/plugins/vue3-lottie.client.ts";
import plugins_vue3_youtube_ts_85VsOCUAX8 from "/vercel/path0/plugins/vue3-youtube.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_graphql_client_dist_runtime_plugin_mjs_jBToDIZpFa,
  plugins_gsap_ts_qE0fizvEy0,
  plugins_livePreview_ts_9NO16x2OtG,
  plugins_removePTags_ts_NNOtr2gdeF,
  plugins_sentry_client_js_GoGQuZo4Et,
  plugins_veeValidate_ts_U1l0qzVbBC,
  plugins_vue_cloudinary_image_statamic_js_CYF7m5qqCy,
  plugins_vue_cloudinary_image_js_AN6cd3OYHt,
  plugins_vue_gtag_client_js_fbnXi0Aqdd,
  plugins_vue3_lottie_client_ts_xXNcInN2H5,
  plugins_vue3_youtube_ts_85VsOCUAX8
]