import { defineAsyncComponent } from 'vue'
import { defineNuxtPlugin } from '#app'

const components = {
  Accordion: defineAsyncComponent(() => import("/vercel/path0/components/Accordion.vue" /* webpackChunkName: "components/accordion" */).then(c => c.default || c)),
  AnimatedContainer: defineAsyncComponent(() => import("/vercel/path0/components/AnimatedContainer.vue" /* webpackChunkName: "components/animated-container" */).then(c => c.default || c)),
  AppButton: defineAsyncComponent(() => import("/vercel/path0/components/AppButton.vue" /* webpackChunkName: "components/app-button" */).then(c => c.default || c)),
  AppLink: defineAsyncComponent(() => import("/vercel/path0/components/AppLink.vue" /* webpackChunkName: "components/app-link" */).then(c => c.default || c)),
  AppLinkWithIcon: defineAsyncComponent(() => import("/vercel/path0/components/AppLinkWithIcon.vue" /* webpackChunkName: "components/app-link-with-icon" */).then(c => c.default || c)),
  ArrowButton: defineAsyncComponent(() => import("/vercel/path0/components/ArrowButton.vue" /* webpackChunkName: "components/arrow-button" */).then(c => c.default || c)),
  BlogOverview: defineAsyncComponent(() => import("/vercel/path0/components/BlogOverview.vue" /* webpackChunkName: "components/blog-overview" */).then(c => c.default || c)),
  Breadcrumbs: defineAsyncComponent(() => import("/vercel/path0/components/Breadcrumbs.vue" /* webpackChunkName: "components/breadcrumbs" */).then(c => c.default || c)),
  Breaky: defineAsyncComponent(() => import("/vercel/path0/components/Breaky.vue" /* webpackChunkName: "components/breaky" */).then(c => c.default || c)),
  ComponentsLoader: defineAsyncComponent(() => import("/vercel/path0/components/ComponentsLoader.vue" /* webpackChunkName: "components/components-loader" */).then(c => c.default || c)),
  Container: defineAsyncComponent(() => import("/vercel/path0/components/Container.vue" /* webpackChunkName: "components/container" */).then(c => c.default || c)),
  ContentSection: defineAsyncComponent(() => import("/vercel/path0/components/ContentSection.vue" /* webpackChunkName: "components/content-section" */).then(c => c.default || c)),
  CookieBanner: defineAsyncComponent(() => import("/vercel/path0/components/CookieBanner.vue" /* webpackChunkName: "components/cookie-banner" */).then(c => c.default || c)),
  EntryCard: defineAsyncComponent(() => import("/vercel/path0/components/EntryCard.vue" /* webpackChunkName: "components/entry-card" */).then(c => c.default || c)),
  EventCard: defineAsyncComponent(() => import("/vercel/path0/components/EventCard.vue" /* webpackChunkName: "components/event-card" */).then(c => c.default || c)),
  EventCardAccordion: defineAsyncComponent(() => import("/vercel/path0/components/EventCardAccordion.vue" /* webpackChunkName: "components/event-card-accordion" */).then(c => c.default || c)),
  EventOverview: defineAsyncComponent(() => import("/vercel/path0/components/EventOverview.vue" /* webpackChunkName: "components/event-overview" */).then(c => c.default || c)),
  Filter: defineAsyncComponent(() => import("/vercel/path0/components/Filter.vue" /* webpackChunkName: "components/filter" */).then(c => c.default || c)),
  Floater: defineAsyncComponent(() => import("/vercel/path0/components/Floater.vue" /* webpackChunkName: "components/floater" */).then(c => c.default || c)),
  HerosLoader: defineAsyncComponent(() => import("/vercel/path0/components/HerosLoader.vue" /* webpackChunkName: "components/heros-loader" */).then(c => c.default || c)),
  LeadText: defineAsyncComponent(() => import("/vercel/path0/components/LeadText.vue" /* webpackChunkName: "components/lead-text" */).then(c => c.default || c)),
  ScrollDownIndicator: defineAsyncComponent(() => import("/vercel/path0/components/ScrollDownIndicator.vue" /* webpackChunkName: "components/scroll-down-indicator" */).then(c => c.default || c)),
  SliderButtons: defineAsyncComponent(() => import("/vercel/path0/components/SliderButtons.vue" /* webpackChunkName: "components/slider-buttons" */).then(c => c.default || c)),
  TeamCard: defineAsyncComponent(() => import("/vercel/path0/components/TeamCard.vue" /* webpackChunkName: "components/team-card" */).then(c => c.default || c)),
  TeamOverview: defineAsyncComponent(() => import("/vercel/path0/components/TeamOverview.vue" /* webpackChunkName: "components/team-overview" */).then(c => c.default || c)),
  VideoPlayer: defineAsyncComponent(() => import("/vercel/path0/components/VideoPlayer.vue" /* webpackChunkName: "components/video-player" */).then(c => c.default || c)),
  BlueprintBlogOverview: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/BlogOverview.vue" /* webpackChunkName: "components/blueprint-blog-overview" */).then(c => c.default || c)),
  BlueprintBlogPost: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/BlogPost.vue" /* webpackChunkName: "components/blueprint-blog-post" */).then(c => c.default || c)),
  BlueprintContentPage: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/ContentPage.vue" /* webpackChunkName: "components/blueprint-content-page" */).then(c => c.default || c)),
  BlueprintEvent: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/Event.vue" /* webpackChunkName: "components/blueprint-event" */).then(c => c.default || c)),
  BlueprintEventOverview: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/EventOverview.vue" /* webpackChunkName: "components/blueprint-event-overview" */).then(c => c.default || c)),
  BlueprintJob: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/Job.vue" /* webpackChunkName: "components/blueprint-job" */).then(c => c.default || c)),
  BlueprintPage: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/Page.vue" /* webpackChunkName: "components/blueprint-page" */).then(c => c.default || c)),
  BlueprintSection: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/Section.vue" /* webpackChunkName: "components/blueprint-section" */).then(c => c.default || c)),
  BlueprintTeamOverview: defineAsyncComponent(() => import("/vercel/path0/components/blueprint/TeamOverview.vue" /* webpackChunkName: "components/blueprint-team-overview" */).then(c => c.default || c)),
  ContentAccordionGroup: defineAsyncComponent(() => import("/vercel/path0/components/content/AccordionGroup.vue" /* webpackChunkName: "components/content-accordion-group" */).then(c => c.default || c)),
  ContentBlogSlider: defineAsyncComponent(() => import("/vercel/path0/components/content/BlogSlider.vue" /* webpackChunkName: "components/content-blog-slider" */).then(c => c.default || c)),
  ContentContactTeaser: defineAsyncComponent(() => import("/vercel/path0/components/content/ContactTeaser.vue" /* webpackChunkName: "components/content-contact-teaser" */).then(c => c.default || c)),
  ContentDownloads: defineAsyncComponent(() => import("/vercel/path0/components/content/Downloads.vue" /* webpackChunkName: "components/content-downloads" */).then(c => c.default || c)),
  ContentEventCards: defineAsyncComponent(() => import("/vercel/path0/components/content/EventCards.vue" /* webpackChunkName: "components/content-event-cards" */).then(c => c.default || c)),
  ContentGallery: defineAsyncComponent(() => import("/vercel/path0/components/content/Gallery.vue" /* webpackChunkName: "components/content-gallery" */).then(c => c.default || c)),
  ContentGoogleMaps: defineAsyncComponent(() => import("/vercel/path0/components/content/GoogleMaps.vue" /* webpackChunkName: "components/content-google-maps" */).then(c => c.default || c)),
  ContentIconCards: defineAsyncComponent(() => import("/vercel/path0/components/content/IconCards.vue" /* webpackChunkName: "components/content-icon-cards" */).then(c => c.default || c)),
  ContentImages: defineAsyncComponent(() => import("/vercel/path0/components/content/Images.vue" /* webpackChunkName: "components/content-images" */).then(c => c.default || c)),
  ContentImagesAsShapes: defineAsyncComponent(() => import("/vercel/path0/components/content/ImagesAsShapes.vue" /* webpackChunkName: "components/content-images-as-shapes" */).then(c => c.default || c)),
  ContentJobCards: defineAsyncComponent(() => import("/vercel/path0/components/content/JobCards.vue" /* webpackChunkName: "components/content-job-cards" */).then(c => c.default || c)),
  ContentPartnerLogos: defineAsyncComponent(() => import("/vercel/path0/components/content/PartnerLogos.vue" /* webpackChunkName: "components/content-partner-logos" */).then(c => c.default || c)),
  ContentPrivacybee: defineAsyncComponent(() => import("/vercel/path0/components/content/Privacybee.vue" /* webpackChunkName: "components/content-privacybee" */).then(c => c.default || c)),
  ContentRaiseNowWidget: defineAsyncComponent(() => import("/vercel/path0/components/content/RaiseNowWidget.vue" /* webpackChunkName: "components/content-raise-now-widget" */).then(c => c.default || c)),
  ContentSlider: defineAsyncComponent(() => import("/vercel/path0/components/content/Slider.vue" /* webpackChunkName: "components/content-slider" */).then(c => c.default || c)),
  ContentTeaserLargeSlider: defineAsyncComponent(() => import("/vercel/path0/components/content/TeaserLargeSlider.vue" /* webpackChunkName: "components/content-teaser-large-slider" */).then(c => c.default || c)),
  ContentText: defineAsyncComponent(() => import("/vercel/path0/components/content/Text.vue" /* webpackChunkName: "components/content-text" */).then(c => c.default || c)),
  ContentVideoDefault: defineAsyncComponent(() => import("/vercel/path0/components/content/VideoDefault.vue" /* webpackChunkName: "components/content-video-default" */).then(c => c.default || c)),
  HeroBlog: defineAsyncComponent(() => import("/vercel/path0/components/hero/Blog.vue" /* webpackChunkName: "components/hero-blog" */).then(c => c.default || c)),
  HeroDefault: defineAsyncComponent(() => import("/vercel/path0/components/hero/Default.vue" /* webpackChunkName: "components/hero-default" */).then(c => c.default || c)),
  HeroEvent: defineAsyncComponent(() => import("/vercel/path0/components/hero/Event.vue" /* webpackChunkName: "components/hero-event" */).then(c => c.default || c)),
  HeroJob: defineAsyncComponent(() => import("/vercel/path0/components/hero/HeroJob.vue" /* webpackChunkName: "components/hero-job" */).then(c => c.default || c)),
  HeroHome: defineAsyncComponent(() => import("/vercel/path0/components/hero/Home.vue" /* webpackChunkName: "components/hero-home" */).then(c => c.default || c)),
  HeroSmall: defineAsyncComponent(() => import("/vercel/path0/components/hero/Small.vue" /* webpackChunkName: "components/hero-small" */).then(c => c.default || c)),
  IconArrow: defineAsyncComponent(() => import("/vercel/path0/components/icon/Arrow.vue" /* webpackChunkName: "components/icon-arrow" */).then(c => c.default || c)),
  IconArrowDash: defineAsyncComponent(() => import("/vercel/path0/components/icon/ArrowDash.vue" /* webpackChunkName: "components/icon-arrow-dash" */).then(c => c.default || c)),
  IconArrowSimple: defineAsyncComponent(() => import("/vercel/path0/components/icon/ArrowSimple.vue" /* webpackChunkName: "components/icon-arrow-simple" */).then(c => c.default || c)),
  IconBlueString: defineAsyncComponent(() => import("/vercel/path0/components/icon/BlueString.vue" /* webpackChunkName: "components/icon-blue-string" */).then(c => c.default || c)),
  IconBurger: defineAsyncComponent(() => import("/vercel/path0/components/icon/Burger.vue" /* webpackChunkName: "components/icon-burger" */).then(c => c.default || c)),
  IconCheckmark: defineAsyncComponent(() => import("/vercel/path0/components/icon/Checkmark.vue" /* webpackChunkName: "components/icon-checkmark" */).then(c => c.default || c)),
  IconCross: defineAsyncComponent(() => import("/vercel/path0/components/icon/Cross.vue" /* webpackChunkName: "components/icon-cross" */).then(c => c.default || c)),
  IconDownload: defineAsyncComponent(() => import("/vercel/path0/components/icon/Download.vue" /* webpackChunkName: "components/icon-download" */).then(c => c.default || c)),
  IconEar: defineAsyncComponent(() => import("/vercel/path0/components/icon/Ear.vue" /* webpackChunkName: "components/icon-ear" */).then(c => c.default || c)),
  IconEye: defineAsyncComponent(() => import("/vercel/path0/components/icon/Eye.vue" /* webpackChunkName: "components/icon-eye" */).then(c => c.default || c)),
  IconEyeAndEar: defineAsyncComponent(() => import("/vercel/path0/components/icon/EyeAndEar.vue" /* webpackChunkName: "components/icon-eye-and-ear" */).then(c => c.default || c)),
  IconEyeAndEarFull: defineAsyncComponent(() => import("/vercel/path0/components/icon/EyeAndEarFull.vue" /* webpackChunkName: "components/icon-eye-and-ear-full" */).then(c => c.default || c)),
  IconFlag: defineAsyncComponent(() => import("/vercel/path0/components/icon/Flag.vue" /* webpackChunkName: "components/icon-flag" */).then(c => c.default || c)),
  IconHeart: defineAsyncComponent(() => import("/vercel/path0/components/icon/Heart.vue" /* webpackChunkName: "components/icon-heart" */).then(c => c.default || c)),
  IconLogo: defineAsyncComponent(() => import("/vercel/path0/components/icon/Logo.vue" /* webpackChunkName: "components/icon-logo" */).then(c => c.default || c)),
  IconPlay: defineAsyncComponent(() => import("/vercel/path0/components/icon/Play.vue" /* webpackChunkName: "components/icon-play" */).then(c => c.default || c)),
  IconPlus: defineAsyncComponent(() => import("/vercel/path0/components/icon/Plus.vue" /* webpackChunkName: "components/icon-plus" */).then(c => c.default || c)),
  IllustrationEyesAndEars: defineAsyncComponent(() => import("/vercel/path0/components/illustration/EyesAndEars.vue" /* webpackChunkName: "components/illustration-eyes-and-ears" */).then(c => c.default || c)),
  IllustrationSquiggle: defineAsyncComponent(() => import("/vercel/path0/components/illustration/Squiggle.vue" /* webpackChunkName: "components/illustration-squiggle" */).then(c => c.default || c)),
  IllustrationSquiggleDot: defineAsyncComponent(() => import("/vercel/path0/components/illustration/SquiggleDot.vue" /* webpackChunkName: "components/illustration-squiggle-dot" */).then(c => c.default || c)),
  NavigationDesktop: defineAsyncComponent(() => import("/vercel/path0/components/navigation/Desktop.vue" /* webpackChunkName: "components/navigation-desktop" */).then(c => c.default || c)),
  NavigationDesktopDropdown: defineAsyncComponent(() => import("/vercel/path0/components/navigation/DesktopDropdown.vue" /* webpackChunkName: "components/navigation-desktop-dropdown" */).then(c => c.default || c)),
  NavigationFooter: defineAsyncComponent(() => import("/vercel/path0/components/navigation/Footer.vue" /* webpackChunkName: "components/navigation-footer" */).then(c => c.default || c)),
  NavigationHoverUnderline: defineAsyncComponent(() => import("/vercel/path0/components/navigation/HoverUnderline.vue" /* webpackChunkName: "components/navigation-hover-underline" */).then(c => c.default || c)),
  NavigationMain: defineAsyncComponent(() => import("/vercel/path0/components/navigation/Main.vue" /* webpackChunkName: "components/navigation-main" */).then(c => c.default || c)),
  NavigationMobile: defineAsyncComponent(() => import("/vercel/path0/components/navigation/Mobile.vue" /* webpackChunkName: "components/navigation-mobile" */).then(c => c.default || c)),
  NavigationMobilePopup: defineAsyncComponent(() => import("/vercel/path0/components/navigation/MobilePopup.vue" /* webpackChunkName: "components/navigation-mobile-popup" */).then(c => c.default || c)),
  NavigationSimpleLang: defineAsyncComponent(() => import("/vercel/path0/components/navigation/SimpleLang.vue" /* webpackChunkName: "components/navigation-simple-lang" */).then(c => c.default || c)),
  NavigationTab: defineAsyncComponent(() => import("/vercel/path0/components/navigation/Tab.vue" /* webpackChunkName: "components/navigation-tab" */).then(c => c.default || c)),
  UtilsTime: defineAsyncComponent(() => import("/vercel/path0/components/utils/Time.vue" /* webpackChunkName: "components/utils-time" */).then(c => c.default || c))
}

export default defineNuxtPlugin(nuxtApp => {
  for (const name in components) {
    nuxtApp.vueApp.component(name, components[name])
    nuxtApp.vueApp.component('Lazy' + name, components[name])
  }
})
