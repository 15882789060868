<template>
  <nav
    class="fixed -top-full left-[10%] z-[1000] rounded-lg bg-amber-100 py-4 px-8 shadow-md transition-all md:focus-within:top-4"
    aria-label="Accessibility navigation"
  >
    <ul class="space-y-2">
      <li>
        <button
          tabindex="1"
          class="focus-default px-2"
          @click="jumptoid('jump-navigation')"
        >
          zur Navigation springen
        </button>
      </li>
      <li>
        <button
          tabindex="1"
          class="focus-default px-2"
          @click="jumptoid('jump-contend-start')"
        >
          zum Seiteninhalt springen
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
useTabHelper()

function jumptoid(id) {
  let el = document.getElementById(id)

  if (el?.getElementsByTagName('a')[0]) {
    el?.getElementsByTagName('a')[0]?.focus()
  } else {
    el?.focus()
  }

  el?.scrollIntoView({ behavior: 'smooth' })
}
</script>
