import { default as _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta } from "/vercel/path0/pages/[...uri].vue?macro=true";
import { default as _47vercel_47path0_47pages_47info_45screen_46vueMeta } from "/vercel/path0/pages/info-screen.vue?macro=true";
export default [
  {
    name: _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta?.name ?? "uri",
    path: _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta?.path ?? "/:uri(.*)*",
    file: "/vercel/path0/pages/[...uri].vue",
    children: [],
    meta: _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta,
    alias: _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47_91_46_46_46uri_93_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47info_45screen_46vueMeta?.name ?? "info-screen",
    path: _47vercel_47path0_47pages_47info_45screen_46vueMeta?.path ?? "/info-screen",
    file: "/vercel/path0/pages/info-screen.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47info_45screen_46vueMeta,
    alias: _47vercel_47path0_47pages_47info_45screen_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47info_45screen_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/info-screen.vue").then(m => m.default || m)
  }
]