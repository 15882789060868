export const useTabHelper = () => {
  onMounted(() => {
    if (document.getElementsByTagName('section')[0]) {
      document
        .getElementsByTagName('section')[0]
        ?.setAttribute('id', 'jump-contend-start')
    } else {
      document
        .getElementsByTagName('header')[0]
        ?.setAttribute('id', 'jump-contend-start')
    }
  })
}
